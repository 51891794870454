import { DEFAULT_DISPLAY_VALUE } from './applicationReportConstants'
import { appDetailDataBigScreen, appDetailDataSmallScreen, fieldInformationData, headerData } from './reportFunctions'

export function mapConsolidateApplicationApiData(apiData, uomPreference) {
    return mapSessionData()

    function mapSessionData() {
        const products = []
        apiData.coverageMaps.forEach((element) => {
            products.push(element.product.name)
        })

        apiData.sessions.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))

        const sessionIndexes = {}
        apiData.sessions.forEach((element, index) => {
            if (sessionIndexes[element.product.name]) {
                sessionIndexes[element.product.name].push(index)
            }
            else {
                sessionIndexes[element.product.name] = [index]
            }
        })

        const doneProducts = []
        const allPageData = []
        products.forEach((product, index) => {
            if (!doneProducts.includes(product)) {
                const productData = {
                    headerInfo: headerData(apiData, product),
                    subheaderInfo: subheaderData(apiData, sessionIndexes[product]),
                    fieldInformation: fieldInformationData(apiData),
                    mapData: mapData(apiData, sessionIndexes[product], index),
                    applicationDetail: appDetailDataBigScreen(apiData, sessionIndexes[product], uomPreference),
                    applicationDetailSmallScreen: appDetailDataSmallScreen(apiData, sessionIndexes[product], uomPreference),
                    productInfo: productInfoData(apiData, sessionIndexes[product]),
                    ingredientInfo: ingredientData(apiData, index),
                }
                doneProducts.push(product)
                allPageData.push(productData)
            }
        })

        return allPageData
    }

    function subheaderData(apiData, sessionIndexes) {
        let completedArea = 0
        let totalVolume = 0
        let totalWeight = 0
        for (let i = 0; i < sessionIndexes.length; i++) {
            completedArea += apiData.sessions[sessionIndexes[i]].totalArea
            totalVolume += apiData.sessions[sessionIndexes[i]].product.totalVolume
            totalWeight += apiData.sessions[sessionIndexes[i]].product.totalWeight
        }

        let appVol = 0
        let applicationVolumeWeight = null
        if (totalVolume && completedArea) {
            appVol = totalVolume / completedArea
            applicationVolumeWeight = {
                item: 'Avg. App Rate',
                value: appVol || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'L/ha',
                imperialUnits: 'gal/ac',
                baseUnits: 'L/m2',
                precision: 2,
            }
        }

        if (totalWeight && completedArea) {
            appVol = totalWeight / completedArea
            applicationVolumeWeight = {
                item: 'Avg. App Rate',
                value: appVol || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'kg/ha',
                imperialUnits: 'lb/ac',
                baseUnits: 'kg/m2',
                precision: 2,
            }
        }

        // NOTE: array order affects order on page
        const subheaderInfo = []
        subheaderInfo.push({
            item: 'Application Type',
            value: (apiData.sessions[sessionIndexes[0]].product.productType || DEFAULT_DISPLAY_VALUE).replace('_', ' '),
        })
        subheaderInfo.push({
            item: 'Field Area',
            value: Number(apiData.field.area) || DEFAULT_DISPLAY_VALUE,
            isUOM: true,
            metricUnits: 'ha',
            imperialUnits: 'ac',
            precision: 2,
        })
        if (applicationVolumeWeight) {
            subheaderInfo.push(applicationVolumeWeight)
        }
        subheaderInfo.push({
            item: 'Completed Area',
            value: completedArea || DEFAULT_DISPLAY_VALUE,
            isUOM: true,
            metricUnits: 'ha',
            imperialUnits: 'ac',
            precision: 2,
        })

        return subheaderInfo
    }

    function mapData(apiData, sessionIndexes, coverageMapIndex) {
        const mapData = {
            imageData: apiData.coverageMaps[coverageMapIndex].imageData,
            features: apiData.coverageMaps[coverageMapIndex].geoFeatures,
            isLiquid: (apiData.sessions[sessionIndexes[0]].product.liquid === '1'),
        }

        return mapData
    }

    function productInfoData(apiData, sessionIndexes) {
        let completedArea = 0
        let totalVolume = 0
        let totalWeight = 0
        for (let i = 0; i < sessionIndexes.length; i++) {
            completedArea += apiData.sessions[sessionIndexes[i]].totalArea
            totalVolume += apiData.sessions[sessionIndexes[i]].product.totalVolume
            totalWeight += apiData.sessions[sessionIndexes[i]].product.totalWeight
        }

        let appVol = 0
        let applicationRate = {}
        let totalProduct = {}
        if (totalVolume && completedArea) {
            appVol = totalVolume / completedArea
            applicationRate = {
                item: 'App Rate',
                value: appVol || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'L/ha',
                imperialUnits: 'gal/ac',
                baseUnits: 'L/m2',
                precision: 2,
            }
            totalProduct = {
                item: 'Total Product',
                value: totalVolume || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'L',
                imperialUnits: 'gal',
                baseUnits: 'L',
                precision: 2,
            }
        }

        if (totalWeight && completedArea) {
            appVol = totalWeight / completedArea
            applicationRate = {
                item: 'App Rate',
                value: appVol || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'kg/ha',
                imperialUnits: 'lb/ac',
                baseUnits: 'kg/m2',
                precision: 2,
            }
            totalProduct = {
                item: 'Total Product',
                value: totalWeight || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'kg',
                imperialUnits: 'lb',
                baseUnits: 'kg',
                precision: 2,
            }
        }

        const productData = {
            name: apiData.sessions[sessionIndexes[0]].product.name || DEFAULT_DISPLAY_VALUE,
            appRate: applicationRate,
            appliedArea: {item: 'Applied Area', value: completedArea || DEFAULT_DISPLAY_VALUE, isUOM: true, metricUnits: 'ha', imperialUnits: 'ac', precision: 2},
            totalProduct: totalProduct,
        }

        return productData
    }

    function ingredientData(apiData, coverageMapIndex) {
        const ingredients = []

        for (let j = 0; j < apiData.coverageMaps[coverageMapIndex].product.ingredients.length; j++) {
            const ingred = {
                name: apiData.coverageMaps[coverageMapIndex].product.ingredients[j].name,
                rate: Number(apiData.coverageMaps[coverageMapIndex].product.ingredients[j].rate),
                company: apiData.coverageMaps[coverageMapIndex].product.ingredients[j].company,
                quantity: Number(apiData.coverageMaps[coverageMapIndex].product.ingredients[j].quantity),
                liquid: Number(apiData.coverageMaps[coverageMapIndex].product.ingredients[j].liquid),
            }

            ingredients.push(ingred)
        }

        const ingredientRows = []
        for (let i = 0; i < ingredients.length; i++) {
            const metricUnitsRate = ingredients[i].liquid ? 'L/ha' : 'kg/ha'
            const imperialUnitsRate = ingredients[i].liquid ? 'gal/ac' : 'lb/ac'
            const baseUnitsRate = ingredients[i].liquid ? 'L/m2' : 'kg/m2'

            const metricUnitsQuantity = ingredients[i].liquid ? 'L' : 'kg'
            const imperialUnitsQuantity = ingredients[i].liquid ? 'gal' : 'lb'
            const baseUnitsQuantity = ingredients[i].liquid ? 'L' : 'kg'

            ingredientRows.push({
                rowName: ingredients[i].name || DEFAULT_DISPLAY_VALUE,
                rate: {value: Number(ingredients[i].rate) || DEFAULT_DISPLAY_VALUE, isUOM: true, precision: 6, metricUnits: metricUnitsRate, imperialUnits: imperialUnitsRate, baseUnits: baseUnitsRate},
                supplier: {value: ingredients[i].company || DEFAULT_DISPLAY_VALUE},
                totalProduct: {value: Number(ingredients[i].quantity) || DEFAULT_DISPLAY_VALUE, isUOM: true, precision: 4, metricUnits: metricUnitsQuantity, imperialUnits: imperialUnitsQuantity, baseUnits: baseUnitsQuantity},
            })
        }

        const ingredientData = {
            ingredientRows: ingredientRows,
        }

        return ingredientData
    }
}
