import {DEFAULT_DISPLAY_VALUE, LIQUID} from './applicationReportConstants'

export function headerData(apiData, product) {
    const headerInfo = {
        name: apiData.company.companyDisplayName || '',
        addressLine1: apiData.company.addressLine1 || '',
        addressLine2: apiData.company.addressLine2 || DEFAULT_DISPLAY_VALUE,
        addressLine3: apiData.company.addressLine3 || DEFAULT_DISPLAY_VALUE,
        phone: apiData.company.contactNumber || '',
        email: apiData.company.emailAddress || '',
        subtitle: product || DEFAULT_DISPLAY_VALUE,
        workorder: {
            woNumber: apiData.workOrderNumber || DEFAULT_DISPLAY_VALUE,
            woName: apiData.jobName || DEFAULT_DISPLAY_VALUE,
        },
    }

    return headerInfo
}

export function fieldInformationData(apiData) {
    let countyState = DEFAULT_DISPLAY_VALUE
    if (apiData.field.county && apiData.field.state) {
        countyState = apiData.field.county + ' ' + apiData.field.state
    }
    else if (!apiData.field.county && apiData.field.state) {
        countyState = apiData.field.state
    }
    else if (apiData.field.county && !apiData.field.state) {
        countyState = apiData.field.county
    }

    const fieldInformation = {
        customer: apiData.grower.name || 'No Customer',
        office: apiData.grower.telephone1 || DEFAULT_DISPLAY_VALUE,
        address: apiData.grower.address || DEFAULT_DISPLAY_VALUE,
        cell: apiData.grower.telephone2 || DEFAULT_DISPLAY_VALUE,
        email: apiData.grower.email || DEFAULT_DISPLAY_VALUE,
        farm: apiData.farm.name || 'No Farm',
        field: apiData.field.name || 'No Field',
        township: apiData.field.township || DEFAULT_DISPLAY_VALUE,
        countyState: countyState || DEFAULT_DISPLAY_VALUE,
        section: apiData.field.section || DEFAULT_DISPLAY_VALUE,
    }

    return fieldInformation
}

export function appDetailDataBigScreen(apiData, sessionIndexes, uomPreference) {
    return splitApplicationDetail(5, apiData, sessionIndexes, uomPreference)
}

export function appDetailDataSmallScreen(apiData, sessionIndexes, uomPreference) {
    return splitApplicationDetail(2, apiData, sessionIndexes, uomPreference)
}

function appDetailData(apiData, sessionIndexes, uomPreference) {
    const colNames = []
    const completionDate = []
    const startTime = []
    const stopTime = []
    const operator = []
    const license = []
    const completedArea = []
    const totalVolume = []
    const totalWeight = []
    const equipment = []
    const groundSpeed = []
    const boomHeight = []
    const tipTypeTipSize = []
    const tipPressure = []
    const fieldConditions = []
    const isLiquid = apiData.sessions[sessionIndexes[0]].product.productType === LIQUID

    for (let i = 0; i < sessionIndexes.length; i++) {
        const opId = appDetailOperator(apiData, sessionIndexes, i)
        const notes = appDetailNotes(apiData, sessionIndexes, i)
        const tipTypeSize = (apiData.sessions[sessionIndexes[i]].equipments[0].tipType || DEFAULT_DISPLAY_VALUE) + ' / ' + (apiData.sessions[sessionIndexes[i]].equipments[0].tipSize || DEFAULT_DISPLAY_VALUE)
        const groundSpeedProps = {isUOM: true, uomPreference: uomPreference, imperialUnits: 'mph', metricUnits: 'km/h', baseUnits: 'm/sec', removeUnitsIfDefault: true}

        colNames.push(i + 1)
        completionDate.push({value: getFormattedDateString(convertToDate(apiData.sessions[sessionIndexes[i]].startTime)) || DEFAULT_DISPLAY_VALUE})
        startTime.push({value: getFormattedDateTimeString(convertToDate(apiData.sessions[sessionIndexes[i]].startTime)) || DEFAULT_DISPLAY_VALUE})
        stopTime.push({value: getFormattedDateTimeString(convertToDate(apiData.sessions[sessionIndexes[i]].stopTime)) || DEFAULT_DISPLAY_VALUE})
        completedArea.push({value: Number(apiData.sessions[sessionIndexes[i]].totalArea) || DEFAULT_DISPLAY_VALUE, isUOM: true, uomPreference: uomPreference, metricUnits: 'ha', imperialUnits: 'ac', removeUnitsIfDefault: true})
        totalVolume.push({value: Number(apiData.sessions[sessionIndexes[i]].product.totalVolume) || 0, isUOM: true, uomPreference: uomPreference, metricUnits: 'L', imperialUnits: 'gal', baseUnits: 'L', removeUnitsIfDefault: true})
        totalWeight.push({value: Number(apiData.sessions[sessionIndexes[i]].product.totalWeight) || 0, isUOM: true, uomPreference: uomPreference, metricUnits: 'kg', imperialUnits: 'lb', baseUnits: 'kg', removeUnitsIfDefault: true})
        boomHeight.push({value: Number(apiData.sessions[sessionIndexes[i]].equipments[0].height) || DEFAULT_DISPLAY_VALUE, isUOM: true, uomPreference: uomPreference, imperialUnits: 'ft', metricUnits: 'm', baseUnits: 'm', removeUnitsIfDefault: true})
        tipTypeTipSize.push({value: tipTypeSize})
        tipPressure.push({value: apiData.sessions[sessionIndexes[i]].equipments[0].tipPressure || DEFAULT_DISPLAY_VALUE})
        fieldConditions.push({value: notes || DEFAULT_DISPLAY_VALUE})

        if (apiData.operators.length > 0) {
            operator.push({value: apiData.operators[opId].name || DEFAULT_DISPLAY_VALUE})
            license.push({value: apiData.operators[opId].licenseNumber || DEFAULT_DISPLAY_VALUE})
            equipment.push({value: apiData.operators[opId].systemName || DEFAULT_DISPLAY_VALUE})
            groundSpeed.push({...{value: Number(apiData.operators[opId].averageApplicationSpeed) || DEFAULT_DISPLAY_VALUE}, ...groundSpeedProps})
        }
        else {
            operator.push({value: DEFAULT_DISPLAY_VALUE})
            license.push({value: DEFAULT_DISPLAY_VALUE})
            equipment.push({value: DEFAULT_DISPLAY_VALUE})
            groundSpeed.push({...{value: DEFAULT_DISPLAY_VALUE}, ...groundSpeedProps})
        }
    }

    const applicationDetail = {
        tableTitle: 'Application Detail',
        columnNames: colNames,
        completionDate: completionDate,
        startTime: startTime,
        stopTime: stopTime,
        operator: operator,
        license: license,
        completedArea: completedArea,
        totalVolume: totalVolume,
        totalWeight: totalWeight,
        equipment: equipment,
        groundSpeed: groundSpeed,
        boomHeight: boomHeight,
        tipTypeTipSize: tipTypeTipSize,
        tipPressure: tipPressure,
        fieldConditions: fieldConditions,
        isLiquid: isLiquid,
    }

    return applicationDetail
}

function appDetailOperator(apiData, sessionIndexes, i) {
    let opId = 0 // default to operator 0
    for (let j = 0; j < apiData.operators.length; j++) {
        if (apiData.operators[j].fileIndex == apiData.sessions[sessionIndexes[i]].fileIndex && apiData.operators[j].id == apiData.sessions[sessionIndexes[i]].latestOperatorId) {
            opId = j
        }
    }

    return opId
}

function appDetailNotes(apiData, sessionIndexes, i) {
    let notes = null
    if (apiData.notes.length >= apiData.sessions[sessionIndexes[i]].fileIndex + 1) {
        notes = apiData.notes[apiData.sessions[sessionIndexes[i]].fileIndex]
    }

    return notes
}

function splitApplicationDetail(tableColumns, apiData, sessionIndexes, uomPreference) {
    const applicationDetailWhole = appDetailData(apiData, sessionIndexes, uomPreference)

    let tableCount = 0
    const appDetailTableCols = applicationDetailWhole.completionDate.length

    tableCount = (appDetailTableCols - (appDetailTableCols % tableColumns)) / tableColumns
    if (appDetailTableCols % tableColumns) {
        tableCount += 1
    }

    const columnNames = []
    const completionDate = []
    const startTime = []
    const stopTime = []
    const operator = []
    const license = []
    const completedArea = []
    const totalVolume = []
    const totalWeight = []
    const equipment = []
    const groundSpeed = []
    const boomHeight = []
    const tipTypeTipSize = []
    const tipPressure = []
    const fieldConditions = []

    for (let i = 0; i < tableCount; i++) {
        columnNames.push(applicationDetailWhole.columnNames.splice(0, tableColumns))
        completionDate.push(applicationDetailWhole.completionDate.splice(0, tableColumns))
        startTime.push(applicationDetailWhole.startTime.splice(0, tableColumns))
        stopTime.push(applicationDetailWhole.stopTime.splice(0, tableColumns))
        operator.push(applicationDetailWhole.operator.splice(0, tableColumns))
        license.push(applicationDetailWhole.license.splice(0, tableColumns))
        completedArea.push(applicationDetailWhole.completedArea.splice(0, tableColumns))
        totalVolume.push(applicationDetailWhole.totalVolume.splice(0, tableColumns))
        totalWeight.push(applicationDetailWhole.totalWeight.splice(0, tableColumns))
        equipment.push(applicationDetailWhole.equipment.splice(0, tableColumns))
        groundSpeed.push(applicationDetailWhole.groundSpeed.splice(0, tableColumns))
        boomHeight.push(applicationDetailWhole.boomHeight.splice(0, tableColumns))
        tipTypeTipSize.push(applicationDetailWhole.tipTypeTipSize.splice(0, tableColumns))
        tipPressure.push(applicationDetailWhole.tipPressure.splice(0, tableColumns))
        fieldConditions.push(applicationDetailWhole.fieldConditions.splice(0, tableColumns))
    }
    const appDetailDataSplit = {
        tableTitle: applicationDetailWhole.tableTitle,
        columnNames: columnNames,
        completionDate: completionDate,
        startTime: startTime,
        stopTime: stopTime,
        operator: operator,
        license: license,
        completedArea: completedArea,
        totalVolume: totalVolume,
        totalWeight: totalWeight,
        equipment: equipment,
        groundSpeed: groundSpeed,
        boomHeight: boomHeight,
        tipTypeTipSize: tipTypeTipSize,
        tipPressure: tipPressure,
        fieldConditions: fieldConditions,
        isLiquid: applicationDetailWhole.isLiquid,
    }

    return appDetailDataSplit
}

function getFormattedDateString(date) {
    if (!date) {
        return DEFAULT_DISPLAY_VALUE
    }

    return date.toLocaleDateString()
}
function convertToDate(dateString) {
    const newDate = new Date(dateString)
    if (newDate instanceof Date && !isNaN(newDate.getTime())) {
        return newDate
    }

    return null
}

function getFormattedDateTimeString(date) {
    if (!date) {
        return DEFAULT_DISPLAY_VALUE
    }

    return date.toLocaleString([], {
        hour12: false,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    })
}
