import './reportStyles.css'

import {CONSOLIDATED_APPLICATION_REPORT, FIELD_ANALYZER_REPORT, IMPERIAL, METRIC} from './applicationReportConstants'
import React, {Fragment, useEffect, useState} from 'react'

import {Button} from '@atd/rui-base.mui.button'
import {Checkbox} from '@atd/rui-base.mui.checkbox'
import {CircularProgress} from '@atd/rui-base.mui.circular-progress'
import FileFileDownload from 'material-ui/svg-icons/file/file-download'
import FieldAnalyzerReportPage from './fieldAnalyzerReportPage'
import { FormControlLabel } from '@atd/rui-base.mui.all'
import ReportPage from './reportPage'
import axios from '../../lib/axios'
import { mapConsolidateApplicationApiData } from './consolidatedApplicationReportData'
import { mapFieldAnalyzerApiData } from './fieldAnalyzerReportData'
import { useParams } from 'react-router-dom'

const ApplicationReport = () => {
    const [allPageData, setAllPageData] = useState(null)

    const [isLoading, setIsLoading] = useState(true)
    const [jobDataNotFound, setJobDataNotFound] = useState(false)
    const [isCriticalError, setIsCriticalError] = useState(false)
    const [hideNullValues, setHideNullValues] = useState(true)
    const [jobName, setJobName] = useState('')
    const [reportType, setReportType] = useState('')

    const [uomPreference, setUomPreference] = useState(IMPERIAL)

    const urlParams = useParams()
    const workorderGuid = urlParams.guid || null


    useEffect(() => {
        if (!workorderGuid) {
            setIsCriticalError(true)
        }

        async function getConsolidatedApplicationReport() {
            const offsetInHoursAndMinutes = `${(new Date().getTimezoneOffset() / 60) * -1}:${(new Date().getTimezoneOffset() % 60)}:00`

            try {
                const response = await axios.get(`/jobs/net-api/consolidatedapplicationreport/${workorderGuid}?offset=${offsetInHoursAndMinutes}&staticMapShowScale=true`)
                handleReportResponse(response.data)
            }
            catch (e) {
                if (e.response && e.response.status === 404) {
                    setJobDataNotFound(true)
                }
                else {
                    setIsCriticalError(true)
                }
            }
            setIsLoading(false)
        }

        getConsolidatedApplicationReport()
    }, [])

    function toggleUnits(value) {
        if (uomPreference === value) {
            return
        }

        if (uomPreference === METRIC) {
            setUomPreference(IMPERIAL)
        }
        else {
            setUomPreference(METRIC)
        }
    }

    function toggleHideNullValues() {
        setHideNullValues(!hideNullValues)
    }

    async function fetchShapefile() {
        const response = await axios.get('/jobs/net-api/consolidatedapplicationreport/' + workorderGuid + '/shapefile', {responseType: 'blob'})
        const blob = new Blob([response.data], {type: 'application/zip; charset=UTF-8'})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = jobName + '-shapefile.zip'
        link.click()
    }

    return (
        <div>
            {isLoading &&
                <div id="loading-container">
                    <CircularProgress sx={{marginLeft: '50%'}}/>
                </div>
            }

            {(!isLoading && jobDataNotFound) &&
                <h2 className="report-status-text">No job data found</h2>
            }

            {(!isLoading && isCriticalError) &&
                <h2 className="report-status-text">An error occurred while loading the application report</h2>
            }

            {(!isLoading && !jobDataNotFound && !isCriticalError) &&
                <Fragment>
                    <div className="hide-print report-right-aligned report-buttons">
                        <FormControlLabel label="Hide Missing Data" control={<Checkbox checked={hideNullValues}
                            onChange={toggleHideNullValues}/>}/>
                        <Button sx={{margin: '5px'}} variant={uomPreference === IMPERIAL ? 'contained' : 'outlined'}
                            onClick={() => toggleUnits(IMPERIAL)}>
                            Imperial
                        </Button>
                        <Button sx={{margin: '5px'}} variant={uomPreference === METRIC ? 'contained' : 'outlined'}
                            onClick={() => toggleUnits(METRIC)}>
                            Metric
                        </Button>
                        <Button sx={{margin: '5px'}} variant="outlined" onClick={() => fetchShapefile()}>
                            <FileFileDownload/> Shapefile
                        </Button>
                        <Button sx={{margin: '5px'}} variant="outlined" onClick={() => window.print()}>
                            <FileFileDownload/> Print/PDF
                        </Button>
                    </div>
                    {reportType === CONSOLIDATED_APPLICATION_REPORT &&
                        <ReportPage
                            allPageData={allPageData}
                            uomPreference={uomPreference}
                            hideNullValues={hideNullValues}
                        />}
                    {reportType === FIELD_ANALYZER_REPORT &&
                        <FieldAnalyzerReportPage
                            allPageData={allPageData}
                            uomPreference={uomPreference}
                            hideNullValues={hideNullValues}
                        />}
                    <div className="report-footer-webview">
                        <div className="slingshot-copyright">Copyright {new Date().getFullYear()} Slingshot&reg; All
                            Rights Reserved
                        </div>
                        <img className="powered-by-raven-img"
                            src={`${process.env.PUBLIC_URL}/images/Powered_By_Raven.png`} alt="Powered By Raven"/>
                    </div>
                </Fragment>
            }
        </div>
    )

    function handleReportResponse(apiData) {
        switch (apiData.reportType) {
            case CONSOLIDATED_APPLICATION_REPORT:
                setAllPageData(mapConsolidateApplicationApiData(apiData, uomPreference))
                break

            case FIELD_ANALYZER_REPORT:
                setAllPageData(mapFieldAnalyzerApiData(apiData, uomPreference))
                break

            default:
                break
        }

        if (apiData.userPreferredUom === null) {
            setUomPreference(IMPERIAL)
        }
        else if (apiData.userPreferredUom.toLowerCase() === METRIC) {
            setUomPreference(METRIC)
        }
        else if (apiData.userPreferredUom.toLowerCase() === IMPERIAL) {
            setUomPreference(IMPERIAL)
        }
        else {
            setUomPreference(IMPERIAL)
        }
        document.title = `Raven Slingshot-${apiData.jobName}-${apiData.workOrderNumber}-Application Report`
        setJobName(apiData.jobName || apiData.workOrderNumber)
        setReportType(apiData.reportType)
    }
}
export default ApplicationReport
